import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="global-footer">
      <div className="footer-content">
        <p><a href="mailto:support@nestextended.com">support@nestextended.com</a></p>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
    </footer>
  );
}

export default Footer;
