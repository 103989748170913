export function handleScrollAnimation() {
  const elements = document.querySelectorAll('.animate-on-scroll, .section-content, .cta-container');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-visible');
        
        // Trigger animations for child elements
        const h2 = entry.target.querySelector('h2');
        const p = entry.target.querySelector('p');
        const lis = entry.target.querySelectorAll('li');
        
        if (h2) h2.style.animation = 'fadeIn 0.5s ease-out 0.2s forwards';
        if (p) p.style.animation = 'fadeIn 0.5s ease-out 0.4s forwards';
        lis.forEach((li, index) => {
          li.style.animation = `fadeInUp 0.5s ease-out ${0.6 + index * 0.1}s forwards`;
        });
      }
    });
  }, { threshold: 0.1 });

  elements.forEach((el) => observer.observe(el));
}
