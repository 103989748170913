import React from 'react';

function Privacy() {
  return (
    <div className="page-content privacy">
      <h1 className="animate-on-load">Privacy Policy</h1>
      <div className="policy-content">
        <p><strong>Effective Date: 01.08.2024</strong></p>

        <h2>1. Introduction</h2>
        <p>Nest ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our browser extension ("the Extension"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not use the Extension.</p>

        <h2>2. Information We Collect</h2>
        <p><strong>Non-Personal Information:</strong> We do not collect personal information. However, we may collect non-personal information related to the performance and usage of the Extension, such as anonymized data about tab management and storage usage, to improve the Extension's functionality.</p>
        <p><strong>Storage Data:</strong> The Extension may store data related to your tab management, such as categorized tabs, snoozed tabs, and backup information. This data is stored locally on your device and is not transmitted to any external servers.</p>

        <h2>3. How We Use Your Information</h2>
        <p><strong>To Provide and Improve the Extension:</strong> The data stored locally is used to provide you with the functionalities of the Extension, including organizing tabs, snoozing tabs, and backing up tabs. We may use anonymized data to improve the Extension's features and performance.</p>
        <p><strong>Notifications:</strong> The Extension may use notifications to alert you about the status of your tabs, such as when snoozed tabs are restored.</p>

        <h2>4. Information Sharing and Disclosure</h2>
        <p><strong>No Sharing:</strong> We do not share or disclose your information to third parties. All data processed by the Extension is stored locally on your device and is not transmitted externally.</p>

        <h2>5. Data Security</h2>
        <p>We implement reasonable security measures to protect the data stored by the Extension. However, please be aware that no method of electronic storage or transmission is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>6. Your Choices</h2>
        <p><strong>Local Data Management:</strong> You can manage or delete the data stored by the Extension at any time through your browser settings. Uninstalling the Extension will remove all associated data from your device.</p>
        <p><strong>Permissions:</strong> You can control the permissions granted to the Extension through your browser's settings. However, please note that certain permissions are necessary for the Extension's core functionality.</p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any significant changes by updating the "Effective Date" at the top of this policy. Your continued use of the Extension after any changes indicates your acceptance of the updated policy.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at support@nestextended.com.</p>
      </div>
    </div>
  );
}

export default Privacy;
