import React, { useEffect } from 'react';
import { handleScrollAnimation } from '../scrollAnimation';

function Home() {
  useEffect(() => {
    handleScrollAnimation();
  }, []);

  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <h1 className="animate-on-load">Your Intelligent Tab Manager for Chrome</h1>
          <p className="sub-headline">
            Experience seamless tab management with Nest, the best Tab Manager for Chrome. Organize, snooze, and restore tabs effortlessly.
          </p>
          <a href="https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en" className="cta-button-top" target="_blank" rel="noopener noreferrer">
            <strong>Install</strong> <span className="light-text-2">Chrome Tab Manager Now.</span>
          </a>
        </div>
      </section>
      
      <section className="dark-bg-section">
        <div className="section-content animate-on-scroll">
          <div className="text-content">
            <h2>Snooze Tabs Boost Productivity and <span className="light-text">Restore Later</span></h2>
            <p>
              Too many tabs distracting you? With Nest, you can snooze tabs and bring them back exactly when you need them. 
              Set a custom time for each tab or snooze entire categories to maintain focus.
            </p>
            <ul>
              <li>Temporarily hide tabs from view.</li>
              <li>Set reminders for when you need them back.</li>
              <li>Snooze individual tabs or entire categories.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="/SnoozeTabs.png" alt="Snooze Tabs Feature" />
          </div>
        </div>
      </section>
      
      <section className="alt-dark-bg-section">
        <div className="section-content animate-on-scroll">
          <div className="text-content">
            <h2>Easy <span className="light-text">Tab Restoration</span> Never Lose Your Work</h2>
            <p>
              Accidentally closed a tab? No problem. Nest's automatic backup feature ensures you can restore any tab at any time, making sure you never lose your important work.
            </p>
            <ul>
              <li>Automatic backups of all open and closed tabs.</li>
              <li>Restore tabs with one click.</li>
              <li>View a list of previously closed tabs and recover them easily.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="/Backup.gif" alt="Tab Backup and Restoration Feature" />
          </div>
        </div>
      </section>
      
      <section className="light-bg-section">
        <div className="section-content animate-on-scroll">
          <div className="text-content">
            <h2>Take <span className="light-text">Quick Notes</span> While Browsing</h2>
            <p>
              Nest makes it easy to take notes without leaving your current tab. Whether you're researching, brainstorming, or tracking tasks, our quick notes feature ensures you have everything you need in one place.
            </p>
            <ul>
              <li>Take notes for specific tabs.</li>
              <li>Organize notes by category or topic.</li>
              <li>Access your notes anytime, right within your browser.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="/Notes.png" alt="Quick Notes Feature" />
          </div>
        </div>
      </section>
      
      <section className="alt-light-bg-section">
        <div className="section-content animate-on-scroll">
          <div className="text-content">
            <h2><span className="light-text-1">Organize Your Tabs</span> Into Custom Categories</h2>
            <p>
              Tired of juggling countless tabs? Nest allows you to group your tabs into custom categories, 
              so you can focus on what's important and declutter your browsing experience.
            </p>
            <ul>
              <li>Create and name your own tab categories.</li>
              <li>Drag and drop tabs into categories for easy management.</li>
              <li>Switch between categories to quickly access the tabs you need.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="/TabsCategories.gif" alt="Tab Categories Feature"/>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <div className="cta-container animate-on-scroll">
          <p className="cta-text">Ready to revolutionize your browsing?</p>
          <a href="https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en" 
             className="cta-button-primary" 
             target="_blank" 
             rel="noopener noreferrer">
            Start Organizing Your Tabs Today
          </a>
        </div>
      </section>

      <section className="dark-bg-section">
        <div className="section-content animate-on-scroll">
          <div className="text-content">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-item">
              <h3>How does Nest work as a tabmanager?</h3>
              <p>Nest functions as an intelligent tabmanager for Chrome, allowing you to organize, categorize, and easily navigate through your tabs. It provides features like tab grouping and searching</p>
            </div>
            <div className="faq-item">
              <h3>Can Nest help me manage multiple Chrome windows?</h3>
              <p>Absolutely! As a comprehensive tabmanager, Nest can help you manage tabs across multiple Chrome windows. You can easily move tabs between windows, group related tabs, and maintain organization even with numerous open windows.</p>
            </div>
            <div className="faq-item">
        <h3>How to restore tabs using Nest?</h3>
        <p>Restoring tabs with Nest is simple and efficient. Use the Nest icon in your Chrome toolbar to open the extension. Navigate to the "Backup" section. You'll see a container of recently closed tabs. Drag the tab you want to restore into the open tabs container. The tab will reopen in your browser. If you want to restore or close multiple tabs, you can do so by selecting them and clicking the "Restore" button. Nest will then reopen all the selected tabs in your browser.</p>
      </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
